import React, {useEffect, useState, useRef} from 'react';
import {Table, PopConfirm, Icon, Tooltip, Card, notification, displayDate} from '../../components/Elements/appUtils'
import {getAgentFxn, updateAgentFxn} from "../auths/actions";
import {useDispatch} from "react-redux";
import {FilterEachFields} from "../../components/_utils/filterUtils";
import CheckBoxComponent from "../../components/Elements/CheckBox";
import CountryJson from '../../assests/jsonFiles/countries.json';
import _ from "lodash";
import StatesJson from "../../assests/jsonFiles/states.json";
import UserRightComponent from "../../userRights";
import ChangePasswordComponent from "../manager/components/changeTeamPassword";

const {Group} = CheckBoxComponent;


const ViewComponent = ({columns, onChange}) => {
    const filteredColumns = columns.filter((col) => col.key !== "serialNumber");
    let initialChecked = columns.filter(
        (col) =>
            col.key !== "serialNumber" &&
            col.key !== "actions" &&
            col.key !== "certificate"
    );

    const [selectedColumns, setSelectedColumns] = useState(
        initialChecked.map((col) => col.key)
    );

    const handleCheckboxChange = (selected) => {
        setSelectedColumns(selected);
        onChange(selected);
    };

    return (
        <div className="dropdown">
            <button
                className="btn btn-outline-primary dropdown-toggle hide-arrow"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{marginRight: 0, padding: "4px 15px 2px 8px"}}
            >
                <div className="position-relative">
                    <i className={"menu-icon tf-icons bx bx-list-ul"} style={{marginRight: "3px !important"}}/>
                    View
                    <span className="badge rounded-pill bg-danger border"/>
                </div>
            </button>
            <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton"
                style={{minWidth: "auto", animation: "slide-up 0.3s"}}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="menuBox" style={{padding: "10px", minWidth: "160px"}}>
                    <Group
                        options={filteredColumns.map((col) => col.key)}
                        value={selectedColumns}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox-group"
                    />
                </div>
            </ul>
        </div>
    );
};

const {TableCard} = Card;

const AgentsList = (props) => {
    let {currentUser} = props;
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [total, setTotal] = useState(0);
    const [allstates, setAllStates] = useState([]);
    let [updatePasswordState, setUpdatePasswordState] = useState({
        visible: false,
        name: "",
        userId: ""
    })
    const loadState = (countryName) => {
        const countryId = CountryJson.filter((item) => item.name === countryName)[0].id;
        if (countryId) {
            let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
            return filterArr;
        }
        return [];
    }

    const filterInput = [
        {
            key: "companyName",
            label: "Company Name",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "name",
            label: "Person Name",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "countryName",
            label: "Country",
            span: "col-md-3",
            type: "select",
            options: CountryJson.map((country) => country.name),
            showSearch: true,
            value: 'India',
            getValue: (value) => {
                if (value) {
                    const jsonState = loadState(value)
                    setAllStates(jsonState);
                }
            }
        },
        {
            key: "stateName",
            label: "State",
            span: "col-md-3",
            type: "select",
            options: allstates.map((state) => state.name),
            showSearch: true,
        },
        {
            key: "cityName",
            label: "City",
            span: "col-md-3",
            type: "text"
        },

        {
            key: "mobile",
            label: "Phone No",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "email",
            label: "Email",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "address",
            label: "Address",
            span: "col-md-3",
            type: "text"
        }
    ];

    const events = {
        approveAgent: async (agentId) => {
            let resp = await dispatch(updateAgentFxn({agentId, status: 'Approved'}))
            if (resp && resp.success) {
                events.reloadTable()
            }
        },
        rejectAgent: async (agentId) => {
            let resp = await dispatch(updateAgentFxn({agentId, status: 'Reject'}))
            if (resp && resp.success) {
                events.reloadTable()
            }
        },
        reloadTable: () => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        },
        showChangePassword: (record) => {
            setUpdatePasswordState({
                visible: true,
                name: record.name,
                userId: record.userId,
            })
        },
        hideChangePassword: (record) => {
            setUpdatePasswordState({
                visible: false,
                name: "",
                userId: ""
            })
        },
    }

    const columns = [
        {title: "Sr. No.", key: "serialNumber", render: (text, record, index) => index + 1},
        {title: "Company Name", dataIndex: "companyName", key: "companyName"},
        {title: "Person Name", dataIndex: "name", key: "name"},
        {title: "Country", dataIndex: "countryName", key: "countryName"},
        {title: "State", dataIndex: "stateName", key: "stateName"},
        {title: "City", dataIndex: "cityName", key: "cityName"},
        {title: "Pin/Postal Code", dataIndex: "pincode", key: "pincode"},
        {title: "Phone No", dataIndex: "mobile", key: "mobile"},
        {title: "Email", dataIndex: "email", key: "email"},
        {title: "Address", dataIndex: "address", key: "address"},
        {
            title: "Certificate",
            dataIndex: "certificate",
            key: "certificate",
            render: (certificate, record) =>
                certificate && certificate.path ? (
                    <a href={certificate.path} target="_blank"
                       className={'btn rounded-pill btn-outline-primary btn-sm'}>
                        Certificate
                    </a>
                ) : null,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <>
                    {record.status === 'Pending' ? <label className="badge bg-label-secondary"> Pending </label> : ""}
                    {record.status === 'Approved' ? <label className="badge bg-label-primary"> Approved </label> : ""}
                    {record.status === 'Reject' ? <label className="badge bg-label-danger"> Rejected </label> : ""}
                </>
            )
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 150,
            render: (text, record) => {
                return (
                    <>
                        <UserRightComponent route={{right: "agents", rightType: "edit"}} name={'edit-agent'}>
                            <div className={'rowFlex'}>

                                {record.status == "Pending" ? <>
                                    <Tooltip overlay='Approve Agent'>
                                        <PopConfirm
                                            title="Are you sure you want to approve this agent?"
                                            onConfirm={() => events.approveAgent(record._id)}>
                                            <a className={'btn rounded-pill btn-outline-primary btn-sm mr-1'}>
                                                Approve
                                            </a>
                                        </PopConfirm>
                                    </Tooltip>
                                    <Tooltip overlay='Reject Agent'>
                                        <PopConfirm
                                            title="Are you sure you want to reject this agent?"
                                            onConfirm={() => events.rejectAgent(record._id)}>
                                            <a className={'btn rounded-pill btn-outline-danger btn-sm'}>
                                                Reject
                                            </a>
                                        </PopConfirm>
                                    </Tooltip>

                                </> : null}

                            </div>
                        </UserRightComponent>


                        {currentUser && currentUser.userType == "university" && record.status == "Approved" ?
                            <Tooltip title={'Change Password'}>
                                <a onClick={() => {
                                    events.showChangePassword(record)
                                }} className={'cursor-pointer'}>
                                    <i className={'menu-icon tf-icons bx bxs-key'}/>
                                </a>
                            </Tooltip>
                            : null}


                    </>

                );
            }
        }
    ];


    const initialColumns = [
        'serialNumber', 'name', 'status', 'companyName', 'countryName', 'email', 'certificate', 'actions'
    ]

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(getAgentFxn({
                ...params,
                regExFilters: ['address', 'email', 'mobile', 'cityName', 'stateName', 'countryName', 'name', 'companyName']
            }));
            setTotal(resp.total);
            resolve(resp);
        });
    };


    return (
        <>
            <TableCard title={`Agent List (Total: ${total})`}>

                <Table
                    filterFields={filterInput}
                    initialColumns={initialColumns}
                    key={columns}
                    apiRequest={apiRequest}
                    columns={columns}
                    bordered
                    ref={tableRef}
                />

            </TableCard>


            {updatePasswordState.visible ?
                <ChangePasswordComponent
                    {...updatePasswordState}
                    onClose={events.hideChangePassword}
                /> : null}

        </>
    );
}
export default AgentsList;



