import React, {useState, useEffect} from 'react'

import {InputBox, notification, Card, Drawer, Button, Form} from "../../../components/Elements/appUtils";
import {updatePassword} from "../../auths/actions";
import {GetEachFormFields} from "../../../components/_utils/formUtils";

const ChangePasswordByAdmin = (props) => {
    let {name, userId, visible, onClose} = props;
    let {form: {getFieldDecorator, getFieldError}} = props


    let inputTypes = [
        {
            key: "password",
            label: "Password",
            type: "text",
            required: true,
            placeholder: "Enter Password",
        },
        {
            key: "confirmPassword",
            label: "Confirm Password",
            type: "text",
            required: true,
            placeholder: "Enter Confirm Password",
        },
    ]


    const handleSubmit = async (e) => {
        e.preventDefault()
        const {form} = props
        form.validateFields(async (err, valData) => {
            if (!err) {
                let {password, confirmPassword} = valData;
                if (password.length < 6) {
                    return notification.warning({message: 'Enter minimum 6 digit password.'})
                }
                if (password !== confirmPassword) {
                    return notification.warning({message: 'Password not match.'})
                }
                let resp = await updatePassword({userId: userId, password})
                if (resp.success) {
                    notification.success({message: 'Password changed successfully'})
                    onClose()
                } else {
                    notification.error({message: 'Password not changed'})
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };

    return (
        <Drawer
            title={`Change Password for ${name}`}
            visible={visible}
            placement="right"
            closable={true}
            width={'35%'}
            onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <div className={'row'}>
                    {inputTypes.map((item, key) => {
                        return !item.hidden ? (
                            <div className={`${item.span ? item.span : "col-md-12"}`}
                                 key={key}>
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    {...props.form}
                                    item={item}
                                    keyAccessor={item.keyAccessor}
                                    valueAccessor={item.valueAccessor}
                                />
                            </div>
                        ) : null;
                    })}
                </div>

                <button type="submit" className="btn btn-primary mt-5">
                    Submit
                </button>
            </Form>

        </Drawer>
    )
}
export default Form.create()(ChangePasswordByAdmin);
