import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {dateFormat, Form, notification} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addCourseFxn, courseListFxn} from "../../course/action";
import axios from "axios";
import { apiUrl } from "../../../settings";
import { getToken } from "../../../request";
import moment from "moment";
import _ from "lodash";
import { conditionOptions } from "../../../components/_utils/_utils";
import { getAllAgentFxn } from "../../manager/action";
import {addActivityFxn, addMoreApplicationFxn, addStudentFxn, updateIntakeFxn} from "../actions";
import StatesJson from "../../../assests/jsonFiles/states.json";
import CountryJson from "../../../assests/jsonFiles/countries.json";

const initState = {
    courseName: "",
    courseCode: "",
    duration: "",
    intake: [],
    startDate: undefined,
    endDate: undefined,
    description: "",
};

const AddMoreApplication = (props) => {
    const {
        form: { getFieldDecorator, getFieldError, setFieldsValue },
    } = props;
    let { courseId, studentId, record } = props;
    const [courseList, setCourseList] = useState([]);
    const [marketingManagerList, setMarketingManagerList] = useState([]);
    const [state, setState] = useState(initState);
    const [intakeList, setIntakeList] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [courseData, setCourseData] = useState(initState);
    let [intake, setIntake] = useState([]);
    const [courseInfo, setCourseInfo] = useState({
        courseName: "",
        courseCode: "",
        courseId: "",
    });
    const user = JSON.parse(localStorage.getItem("user"));
    const [agentList, setAgentList] = useState([]);
    useEffect(() => {
        // events.loadAgentFxn()
    }, [agentList])
    const events = {
        loadAgentFxn: () => {
            if (user.userType == "agent" && agentList && agentList.length) {
                let singleAgent = agentList[0]
                setFieldsValue({agentId: singleAgent._id})
                setTimeout(() => {
                    events.chooseAgent(singleAgent._id)
                }, 200)
            }
        },
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        loadState: (countryId) => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
                return filterArr;
            }
            return [];
        },
        chooseCountry: (value) => {
            let country = _.find(CountryJson, (item) => item.name === value);
            if (country) {
                setFieldsValue({countryName: value})
                setAllStates(country.id ? events.loadState(country.id) : []);
            }
        },
        chooseState: (value) => {
            setFieldsValue({stateName: value})
        },
        chooseCourse: (courseId) => {
            let findCourse = _.find(courseList, (item) => {
                return item && item._id == courseId
            })
            if (findCourse) {
                let data = []
                _.each(findCourse.intakeList, (item) => {
                    if (item) {
                        item.value = `${item.month}-${item.year}`
                        item.label = `${item.month}, ${item.year}`
                        data.push(item)
                    }
                })
                setIntakeList(data)
            }
            setFieldsValue({courseId: courseId})
        },
        chooseIntake: (value) => {
            let findIntake = _.find(intakeList, (item) => {
                return item && item.value == value
            })
            if (findIntake) {
                setFieldsValue({
                    intakeTemp: value,
                    startDate: findIntake.startDate ? findIntake.startDate : undefined,
                    endDate: findIntake.endDate ? findIntake.endDate : undefined,
                    expirationDate: findIntake.startDate ? moment(findIntake.startDate, dateFormat).add(15, 'day').format(dateFormat) : undefined,
                })
            }
            if (value) {
                let intakeVal = value.split('-')
                let intake = {
                    month: intakeVal[0],
                    year: intakeVal[1]
                }
                setIntake(intake)
            } else {
                setIntake({})
            }
        },
        chooseAgent: (data) => {
            let obj = {
                agentId: data
            }
            let findAgent = _.find(agentList, (item) => {
                return item && item._id == data
            })
            if (findAgent && findAgent.marketingManager) {
                obj.marketingManagerId = findAgent.marketingManager.userId
                setMarketingManagerList([findAgent.marketingManager])
            }
            setFieldsValue({
                ...obj
            })
        },
        setDefaultFields: () => {
            setFieldsValue({
                issueDate: moment().format(dateFormat)
            })
        }
    };

    const listEvents = {
        loadCourseList: async () => {
            try {
                let params = {
                    count: 50,
                    results: 50,
                };
                const { data } = await courseListFxn({ params });
                const courseData = data.data.filter((item) => item._id === courseId);
                setCourseList(data.data);
            } catch (error) {
                console.error("Failed to load course list:", error);
            }
        },
    };

    useEffect(() => {
        if (courseList.length) events.chooseCourse(courseId);
    }, [courseList]);

    useEffect(() => {
        if (courseId) {
            events.loadCourseData(courseId);
        }
    }, [courseId]);
    useEffect(() => {
        listEvents.loadCourseList();
        events.setDefaultFields()
    }, []);

    const inputSchema = [
        {
            key: "courseId",
            label: "Course",
            type: "select",
            required: true,
            options: courseList.map((course) => ({
                label: course.courseName,
                value: course._id,
            })),
            span: "col-md-6",
            placeholder: "Select Course",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label,
            onChange: (value) => {
                events.chooseCourse(value)
            },
        },
        {
            key: "intakeTemp",
            label: "Intake",
            type: "select",
            options: intakeList,
            span: "col-md-6",
            placeholder: "Select Intake",
            required: true,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.label}`,
            onChange: (x) => {
                events.chooseIntake(x)
            }
        },
        {
            key: "isEsl",
            label: "ESL",
            type: "radio",
            required: true,
            options: conditionOptions,
            span: "col-md-6",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setFieldsValue({
                    isEsl: value
                })
            },
        },
        {
            key: "issueDate",
            label: "Issue Date  (DD/MM/YYYY)",
            required: true,
            type: "customDate",
            span: "col-md-6",
            placeholder: "Choose Issue Date",
            onChange: (value) => {
                setFieldsValue({
                    issueDate: value
                })
            },
        },
        {
            key: "startDate",
            label: "Start Date",
            type: "customDate",
            span: "col-md-4",
            placeholder: "Start Date",
            disabled: true,
        },
        {
            key: "endDate",
            label: "Completion Date",
            type: "customDate",
            span: "col-md-4",
            placeholder: "Completion Date",
            disabled: true,
        },
        {
            key: "expirationDate",
            label: "Expiration Date",
            type: "customDate",
            span: "col-md-4",
            placeholder: "Choose Expiration Date",
            disabled: true,
            onChange: (value) => {
                setFieldsValue({
                    expirationDate: value
                })
            },
        },
    ];

    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.intake = intake;
                valData.studentId = studentId;
                valData._id = record._id;
                let resp = await dispatch(addMoreApplicationFxn(valData));
                if (resp && resp.success) {
                    form.setFieldsValue({
                        firstName: "",
                        lastName: "",
                        dateOfBirth: "",
                        dateOfBirthString: "",
                        email: "",
                        nationality: undefined,
                        studentType: undefined,
                        mobile: undefined,
                        address: "",
                        countryName: undefined,
                        stateName: undefined,
                        cityName: "",
                        postalCode: "",
                        courseId: undefined,
                        intakeTemp: undefined,
                        isEsl: undefined,
                        issueDate: "",
                        startDate: "",
                        endDate: "",
                        expirationDate: "",
                        agentId: undefined,
                        marketingManagerId: undefined
                    });
                    const { tableReload, onClose } = props;
                    tableReload();
                    if(onClose){
                        onClose();
                    }
                    setIntake({})
                    events.loadAgentFxn()
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <div className="row g-6">
            <div className="col-12">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        {inputSchema.map((field, key) => (
                            <div
                                className={`${field.span ? field.span : "col-md-6"} mb-3`}
                                key={key}
                            >
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    {...props.form}
                                    item={field}
                                    keyAccessor={field.keyAccessor}
                                    valueAccessor={field.valueAccessor}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default Form.create()(AddMoreApplication);
