import React, {useEffect, useState} from "react";
import {
    calculateInvoiceAmount,
    defaultBankChanges,
    Drawer,
    Form,
    notification
} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {generateInvoiceFxn} from "./actions";
import {singleApplicationFxn} from "../student/actions";
import moment from "moment";
import _ from "lodash"

const initState = {
    invoiceAmount: "",
    invoiceDate: undefined,
    dueDate: undefined,
    applicationFee: "",
    materialFee: "",
    studentArchivingFee: "",
    promotion: "",
    commission: "",
    scholarship: "",
    totalOwingNet: "",
    totalOwing: "",
    charges: '17'

};

const GenerateInvoiceDrawer = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldsValue, getFieldValue},
        onClose,
        visible,
        applicationId,
        studentType
    } = props;


    const dispatch = useDispatch();
    const [state, setState] = useState(initState);

    const apiRequest = async () => {
        let resp = await dispatch(singleApplicationFxn({applicationId}));
        if (resp) {
            const {tuitionFee} = resp;
            const {applicationFee, materialFee, studentArchivingFee} = resp.courseId;
            const {scholarshipAmount, scholarshipPercent} = resp;
            setState(prevState => ({
                ...prevState,
                applicationFee,
                materialFee,
                studentArchivingFee,
                invoiceAmount: tuitionFee,
                scholarship: props.scholarship,
                studentId: resp.studentId._id,
                courseId: resp.courseId._id
            }));
            setTimeout(() => {
                setFieldsValue({
                    applicationFee,
                    materialFee,
                    studentArchivingFee,
                    invoiceAmount: tuitionFee,
                    scholarship: resp.scholarship || false,
                    scholarshipPercent: scholarshipPercent || "",
                    scholarshipAmount: resp.scholarship && resp.scholarshipAmount ? parseFloat(resp.scholarshipAmount.toFixed(2)) : undefined,
                    charges: '17'
                });
            }, 200)
        }
    };

    useEffect(() => {
        if (applicationId) {
            apiRequest();
        }
    }, [applicationId]);


    const handleInvoiceDateChange = (value) => {
        const dueDate = moment(value).add(10, 'days');
        setFieldsValue({invoiceDate: value, dueDate});
    };
    useEffect(() => {
        setFieldsValue({charges: studentType == "onshore" ? 0 : defaultBankChanges})
    }, [])

    useEffect(() => {
        getInvoiceCalc()
    }, [getFieldValue('scholarshipPercent'), getFieldValue('commissionPercent'), getFieldValue('charges'),
        getFieldValue('applicationFee'), getFieldValue('studentArchivingFee'), getFieldValue('materialFee'), getFieldValue('promotion')]);

    const getInvoiceCalc = () => {
        let fields = [
            'invoiceAmount',
            'materialFee',
            'applicationFee',
            'studentArchivingFee',
            'promotion',
            'scholarshipPercent',
            'charges',
            "commissionPercent"
        ]
        let data = {
            scholarship: state.scholarship,
            studentType: studentType
        }
        _.each(fields, (item) => {
            data[item] = getFieldValue(item)
        })
        let resp = calculateInvoiceAmount(data);
        if (resp) {
            setFieldsValue({
                totalOwing: resp.totalOwing || 0,
                totalOwingNet: resp.totalOwingNet || 0,
                scholarshipAmount: resp.scholarshipAmount || 0,
                commission: resp.commission || 0,
            })
        }
    }


    const inputSchema = [
        {
            key: "invoiceAmount",
            type: "text",
            label: "Invoice Amount",
            required: true,
            placeholder: "Enter Amount",
            span: 'col-md-6'
        },
        {
            key: "invoiceDate",
            type: "date",
            label: "Invoice Date",
            required: true,
            placeholder: "Select Invoice Date",
            span: 'col-md-6',
            onChange: handleInvoiceDateChange
        },
        {
            key: "dueDate",
            type: "date",
            label: "Due Date",
            required: true,
            placeholder: "Select Due Date",
            span: 'col-md-6',
            disabled: true
        },
        {
            key: "applicationFee",
            type: "text",
            label: "Application Fee",
            required: true,
            placeholder: "Enter Application Fee",
            span: 'col-md-6'
        },
        {
            key: "materialFee",
            type: "text",
            label: "Material Fee",
            required: true,
            placeholder: "Enter Material Fee",
            span: 'col-md-6'
        },
        {
            key: "studentArchivingFee",
            type: "text",
            label: "Student Archiving Fee",
            required: true,
            placeholder: "Enter Student Archiving Fee",
            span: 'col-md-6'
        },
        {
            key: "promotion",
            type: "text",
            label: "Promotion",
            // required: true,
            placeholder: "Enter Promotion Amount",
            span: 'col-md-6',
            // hidden: studentType == "onshore"
        },

        {
            key: "scholarshipPercent",
            type: "text",
            label: "Scholarship (%)",
            required: true,
            placeholder: "Scholarship",
            span: 'col-md-3'
        },
        {
            key: "scholarshipAmount",
            type: "number",
            label: "Scholarship",
            disabled: true,
            placeholder: "Scholarship Amount",
            span: 'col-md-3'
        },

        {
            key: "charges",
            type: "text",
            label: "Bank Charges",
            // required: true,
            placeholder: "Charges",
            span: 'col-md-6',
            // disabled: true,
            // hidden: studentType == "onshore"
        },
        {
            key: "totalOwing",
            type: "text",
            label: "Total Owing",
            required: true,
            placeholder: "Enter Total Owing Amount",
            span: 'col-md-6',
            disabled: true
        },

        {
            key: "commissionPercent",
            type: "text",
            label: "Commission (%)",
            required: true,
            placeholder: "Commission",
            span: 'col-md-3'
        },
        // {
        //     key: "scholarshipAmount",
        //     type: "text",
        //     label: "Scholarship",
        //     required: true,
        //     placeholder: "Enter Scholarship",
        //     span: 'col-md-6',
        //     hidden: !props.scholarship
        // },
        {
            key: "commission",
            type: "text",
            label: "Commission",
            placeholder: "Commission Amount",
            disabled: true,
            span: 'col-md-3'
        },

        {
            key: "totalOwingNet",
            type: "text",
            label: "Total Owing Net",
            required: true,
            placeholder: "Enter Total Owing Amount",
            span: 'col-md-6',
            disabled: true
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.applicationId = applicationId;
                let resp = await dispatch(generateInvoiceFxn(valData));
                if (resp && resp.success) {
                    form.resetFields();
                    setState(initState);
                    onClose();
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <>
            <Drawer onClose={onClose} visible={visible} title={'Generate Invoice'}>
                <Form onSubmit={handleSubmit}>
                    <div className="row">
                        {inputSchema.map((item) => (
                            !item.hidden ? <div className={item.span} key={item.key}>
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    item={item}
                                    onChange={item.onChange}
                                />
                            </div> : null
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </Form>
            </Drawer>
        </>
    );
};

export default Form.create()(GenerateInvoiceDrawer);
