import React, {useEffect, useState} from "react"
import "../../assests/backApp/css/page-auth.css"
import {Form, notification} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {recoverPasswordFxn} from "./actions";
import {useNavigate} from "react-router-dom";

const Login = (props) => {
    const navigate = useNavigate();
    let dispatch = useDispatch()
    let {form} = props;
    const {getFieldDecorator, setFieldsValue} = form;

    let events = {
        submitFinal: (e) => {
            e.preventDefault();
            form.validateFields(async (err, values) => {
                if (!err) {
                    let x = await dispatch(recoverPasswordFxn(values));
                    if (x && x.success) {
                        alert(x.message)
                        setFieldsValue({
                            email: ''
                        })
                    }
                } else {
                    notification.warning({message: "Please enter both email."})
                }
            })
        }
    }

    const openLogin = () => {
        navigate(`/login`);
    }
    const loginComponent = (
        <div>
            <h4 className="mb-1">Welcome to GBC! 👋</h4>
            <br/>
            <br/>
            <p className="mb-6">

            </p>
            <Form className="mb-6" onSubmit={events.submitFinal}>
                <div className="mb-6">
                    <label htmlFor="email" className="form-label">Email</label>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: 'Please input your email address!'
                            }]
                        })(
                            <input placeholder="Enter your email" type={'email'}
                                   className={'form-control'}
                                   id={'new-email'} name={'new-email'}
                                   autoComplete="new-email"/>
                        )}
                    </Form.Item>
                </div>

                <button type={'submit'} className="btn btn-primary d-grid w-100">
                    Reset Password
                </button>
            </Form>
        </div>
    )


    return (
        <>
            <div className={'authentication-wrapper authentication-basic container-p-y app-form'}
                 style={{minHeight: "auto"}}>
                <div className="authentication-inner" style={{marginTop: 50, marginBottom: 50}}>
                    <div className="card px-sm-6 px-0">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="/" className="app-brand-link gap-2">
                                <span className="app-brand-text demo text-heading fw-bold">
                                </span>
                                </a>
                            </div>


                            {loginComponent}


                            <p className="text-center">
                                <span>Already have an account ?</span>
                                <a onClick={openLogin} className={'hyperLink hover'}>
                                    <span> Login Here</span>
                                </a>
                            </p>
                            <br/>
                            <br/>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
const WrappedLogin = Form.create()(Login)
export default WrappedLogin
