import React from "react"
import {Modal} from "../../../components/Elements/appUtils";
import CourseEdit from "../components/courseEdit";
import IntakeEdit from "../components/intakeEdit";
import AddMoreApplication from "../components/addMoreApplication";

const AddMoreAppModal = (props) => {
    let {visible, onClose} = props;
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'50%'}
            placement='right'
            title={(
                <h5 className="card-action-title mb-0">
                    <b> Course Details</b>
                </h5>
            )}>
            <AddMoreApplication {...props}/>
        </Modal>
    )
}
export default AddMoreAppModal;
