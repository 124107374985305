import React, { useState, useEffect } from "react";
import { Drawer, notification, Form, Button } from "../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { months, getIntakeYear, conditionOptions } from "../../components/_utils/_utils";
import { singleCourseFxn, addIntakeToCourse } from "../course/action";

const initState = {
    month: "",
    year: "",
    startDate: "",
    endDate: "",
    secondYearStartDate: "",
    secondYearEndDate: "",
    active: false
};

const UpdateIntakeDrawer = (props) => {
    const dispatch = useDispatch();
    const { form: { getFieldDecorator, getFieldError, setFieldsValue, validateFields, resetFields } } = props;
    const { visible, onClose, courseId, pathway, onSuccess } = props;

    const [state, setState] = useState(initState);

    const apiRequest = async () => {
        if (courseId) {
            const { data } = await dispatch(singleCourseFxn({ _id: courseId }));
            return data.intakeList || [];
        }
        return [];
    };

    useEffect(() => {
        apiRequest();
    }, [courseId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        validateFields(async (err, valData) => {
            if (!err) {
                try {
                    const existingIntakes = await apiRequest();
                    const intakeList = [...existingIntakes, valData];
                    const resp = await dispatch(addIntakeToCourse(
                    {
                        courseId: courseId, intakeList: intakeList
                    }));
                    if (resp && resp.success) {
                        resetFields();
                        onSuccess();
                        onClose();
                    } else {
                        notification.error({
                            message: resp.message || 'Failed to update intake',
                        });
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                    notification.error({
                        message: 'An error occurred while processing your request',
                    });
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields',
                });
            }
        });
    };

    const inputSchema = [
        {
            title: "Month",
            key: "month",
            label: "Intake Month",
            required: true,
            placeholder: "Intake Month",
            type: "select",
            options: months.list,
            span: "col-md-6",
            onChange: (date) => setFieldsValue({ month: date }),
        },
        {
            title: "Year",
            key: "year",
            type: "select",
            label: "Intake Year",
            required: true,
            placeholder: "Intake Year",
            span: "col-md-6",
            options: getIntakeYear(),
            onChange: (date) => setFieldsValue({ year: date }),
        },
        {
            title: "Start Date",
            key: "startDate",
            type: "customDate",
            label: "Start Date (DD/MM/YYYY)",
            required: true,
            placeholder: "Start Date",
            span: "col-md-6",
            onChange: (date) => setFieldsValue({startDate: date}),
        },
        {
            title: "End Date",
            key: "endDate",
            type: "customDate",
            label: "End Date (DD/MM/YYYY)",
            required: true,
            placeholder: "End Date",
            span: "col-md-6",
            onChange: (date) => setFieldsValue({endDate: date}),
        },
        {
            title: "Second Year Start Date",
            key: "secondYearStartDate",
            type: "customDate",
            label: "Second Year Start Date (DD/MM/YYYY)",
            placeholder: "Second Year Start Date",
            span: "col-md-6",
            hidden: pathway !== true,
            required: pathway === true,
            onChange: (date) => setFieldsValue({secondYearStartDate: date}),
        },
        {
            title: "Second Year End Date",
            key: "secondYearEndDate",
            type: "customDate",
            label: "Second Year End Date (DD/MM/YYYY)",
            placeholder: "Second Year End Date",
            span: "col-md-6",
            hidden: pathway !== true,
            required: pathway === true,
            onChange: (date) => setFieldsValue({secondYearEndDate: date}),
        },
        {
            key: "active",
            label: "Status",
            type: "switch",
            span: "col-md-6",
            options: conditionOptions,
            onChange: (data) => setFieldsValue({ active: data }),
        },
    ];

    return (
        <Drawer visible={visible} onClose={onClose} title="Add More Intake">
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    {inputSchema.map((field, key) => (
                        <div className={field.span || "col-md-6"} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={field}
                            />
                        </div>
                    ))}
                </div>
                <Button type="primary" htmlType="submit" className="btn btn-primary">
                    Submit
                </Button>
            </Form>
        </Drawer>
    );
};

export default Form.create()(UpdateIntakeDrawer);
