import Login from "../containers/auths/login";
import Registration from "../containers/auths/signup";
import ForgotPassword from "../containers/auths/forgotPassword";
import ResetPassword from "../containers/auths/resetPassword";

export let authMenuData = [
    {
        name: "Login",
        path: "/login",
        key: "login",
        component: Login,
    },
    {
        name: "Forgot Password",
        path: "/forgot-password",
        key: "forgotPassword",
        component: ForgotPassword,
    },
    {
        name: "Register",
        path: "/register",
        key: "register",
        component: Registration,
    },
    {
        name: 'Reset Password',
        path: '/reset-password/:token',
        component: ResetPassword,
        key: 'resetPassword'
    }

]
