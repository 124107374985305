import {apiUrl} from "../../../settings";

export const addAgentUrl = () => {
    return apiUrl + '/api/agent'
}

export const getAgentsUrl = () => {
    return apiUrl + '/api/getAgent'
}

export const updateAgentUrl = () => {
    return apiUrl + '/api/updateAgent'
}
export const changePasswordUserUrl = () => {
    return apiUrl + '/changePasswordUser'
}

export const recoverPasswordUrl = () => {
    return apiUrl + '/api/recoverPassword'
}

export const resetPasswordUrl = () => {
    return apiUrl + '/api/resetPassword'
}

export const checkRecoverCodeUrl = () => {
    return apiUrl + '/api/checkRecoverCode'
}

export const updatePasswordUrl = () => {
    return apiUrl + '/api/updatePassword'
}
