import {apiUrl} from "../../../settings"

export const addStudentUrl = () => {
    return apiUrl + '/student/add'
}
export const addMoreAppUrl = () => {
    return apiUrl + '/student/addMoreApplication'
}
export const studentListUrl = () => {
    return apiUrl + '/student/list'
}

export const applicationListUrl = () => {
    return apiUrl + '/application/list'
}

export const singleApplicationUrl = () => {
    return apiUrl + '/application/single'
}

export const generateLoaUrl = () => {
    return apiUrl + '/loa/add'
}
export const generateCoOpUrl = () => {
    return apiUrl + '/co-op/add'
}
export const activityListUrl = () => {
    return apiUrl + '/activity/list'
}

export const addNoteUrl = () => {
    return apiUrl + '/note/add'
}



export const NoteListUrl = () => {
    return apiUrl + '/note/list'
}

export const getSingleStudentUrl = () => {
    return apiUrl + '/student/getSingle'
}

export const updateStudentUrl = () => {
    return apiUrl + '/student/update'
}
export const updateStudentImageUrl = () => {
    return apiUrl + '/student/update/image'
}

export const appInvoiceListUrl = () => {
    return apiUrl + '/app-invoice/list'
}

export const updateCourseUrl = () => {
    return apiUrl+ '/application/update-course'
}


export const updateAgentUrl = () => {
    return `${apiUrl}/application/update-agent`;
}

export const updateIntakeUrl = () => {
    return `${apiUrl}/application/update-intake`;
}


export const applicationUpdateAgentUrl = () => {
    return apiUrl + '/application/update-agent'
}
export const updateApplicationStatusUrl = () => {
    return apiUrl + '/application/update-status'
}

export const generateContractUrl = () => {
    return apiUrl + '/contract/generate'
}

export const generateOfferLetterUrl = () => {
    return apiUrl + '/application/generate-offer'
}

export const requiredDocumentsUrl = () => {
    return apiUrl + '/required-documents'
}
export const reGenerateOfferLetterUrl = () => {
    return apiUrl + '/offer-letter/re-generate'
}

export const palRequestUrl = () => {
    return apiUrl + '/api/pal-request'
}
export const checkDocumentRequirementUrl = () => {
    return apiUrl + '/api/check-document-requirement'
}

export const uploadPalUrl = () => {
    return apiUrl + '/api/upload-pal'
}
