import React, {useState, useEffect} from "react";
import {
    Drawer,
    Card,
    Form,
    notification,
} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {months, getIntakeYear, conditionOptions} from "../../components/_utils/_utils";
import {singleCourseFxn} from "../course/action";

const initState = {
    month: "",
    year: "",
    startDate: "",
    endDate: "",
    secondYearStartDate: "",
    secondYearEndDate: "",

};

const IntakeDrawer = (props) => {
    let dispatch = useDispatch();
    let {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props

    let {visible, onClose, records, pathway, onSubmit, courseId, singleIntake} = props;


    const [state, setState] = useState(initState);



    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, valData) => {

            if(pathway === false){
                valData.secondYearStartDate = ''
                valData.secondYearEndDate = ''
            }
            if (!err) {
                onSubmit(valData);
                form.resetFields()
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };



    const inputSchema = [
        {
            title: "Month",
            key: "month",
            label: "Intake Month",
            required: true,
            placeholder: "Intake Month",
            type: 'select',
            options: months.list,
            span: "col-md-6",
            onChange: (date) => {
                setFieldsValue({month: date})
            }
        },
        {
            title: "Year",
            key: "year",
            type: "select",
            label: "Intake Year",
            required: true,
            placeholder: "Intake Year",
            span: "col-md-6",
            options: getIntakeYear(),
            onChange: (date) => {
                setFieldsValue({year: date})
            }
        },
        {
            title: "Start Date",
            key: "startDate",
            type: "customDate",
            label: "Start Date",
            required: true,
            placeholder: "Start Date",
            span: "col-md-6",
            onChange: (date) => {
                setFieldsValue({startDate: date})
            }
        },
        {
            title: "End Date",
            key: "endDate",
            type: "customDate",
            label: "End Date",
            required: true,
            placeholder: "End Date",
            span: "col-md-6",
            onChange: (date) => {
                setFieldsValue({endDate: date})
            }
        },
        {
            title: "Second Year Start Date",
            key: "secondYearStartDate",
            type: "date",
            label: "Second Year Start Date",
            placeholder: "Second Year Start Date",
            span: "col-md-6",
            hidden: pathway !== true,
            required: pathway !== true,
            onChange: (date) => {
                setFieldsValue({secondYearStartDate: date})
            }
        },

        {
            title: "Second Year End Date",
            key: "secondYearEndDate",
            type: "date",
            label: "Second Year End Date",
            placeholder: "Second Year End Date",
            span: "col-md-6",
            hidden: pathway !== true,
            required: pathway !== true,
            onChange: (date) => {
                setFieldsValue({secondYearEndDate: date})
            }
        },

        {
            key: "active",
            label: "Status",
            type: "switch",
            span: "col-md-6",
            options: conditionOptions,
            keyAccessor: (x) => x.value,
            valueAccessor: (x) => `${x.name}`,
            onChange: (data) => {
                setFieldsValue({ active: data });
            },
        },


    ];

    const apiRequest = async (params) => {
        return new Promise(async (resolve) => {
        let {data} = await dispatch(singleCourseFxn({_id:courseId}));
            resolve({data:data?.intakeList})
        })

    }
useEffect(() => {
    apiRequest()
},[])
    return (
        <>
            <Drawer visible={visible} onClose={onClose} title={'Add Intake'}>


                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {inputSchema.map((field,key) => (

                            <div
                            className={`${field.span ? field.span : "col-md-6"}`}
                            key={key}
                          >
                            <GetEachFormFields
                              getFieldDecorator={getFieldDecorator}
                              getFieldError={getFieldError}
                              {...props.form}
                              item={field}
                              keyAccessor={field.keyAccessor}
                              valueAccessor={field.valueAccessor}
                            />
                          </div>
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Drawer>
        </>
    );
};
export default Form.create()(IntakeDrawer);
