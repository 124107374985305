import React, {useEffect, useState} from "react"
import "../../assests/backApp/css/page-auth.css"
import {Form, notification} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {resetPasswordFxn, checkRecoverCodeFxn} from "./actions";
import {useNavigate} from "react-router-dom";

const ResetPassword = (props) => {
    const navigate = useNavigate();
    let [email, setEmail] = useState("")
    let dispatch = useDispatch()
    let {form} = props;
    const {getFieldDecorator, setFieldsValue} = form;
    useEffect(() => {
        events.checkIsLoggedIn()
    }, [])

    let events = {
        checkIsLoggedIn: async () => {
            let resp = localStorage.getItem('token')
            if (resp) {
                navigate('/dashboard')
            } else {
                let {pathname} = window.location
                let token = pathname.split('/').pop()
                let resp1 = await checkRecoverCodeFxn({token})
                if (resp1 && resp1.success) {
                    setEmail(resp1.data)
                } else {
                    navigate('/dashboard')
                }
            }
        },
        submitFinal: (e) => {
            e.preventDefault();
            form.validateFields(async (err, values) => {
                if (!err) {
                    let {pathname} = window.location
                    let token = pathname.split('/').pop()

                    let {password, confirmPassword} = values
                    if (!password) {
                        notification.warning({message: 'Enter new password.'})
                        return
                    }
                    if (password && password.length < 6) {
                        notification.warning({message: 'Enter minimum 6 digit password.'})
                        return
                    }
                    if (!confirmPassword) {
                        notification.warning({message: 'Enter confirm password.'})
                        return
                    }
                    if (password !== confirmPassword) {
                        notification.warning({message: 'Confirm Password not match, enter correct password.'})
                        return
                    }
                    let resp = await dispatch(resetPasswordFxn({password, confirmPassword, token, email}))
                    if (resp && resp.success) {
                        alert(resp.message)
                        setFieldsValue({
                            password: '',
                            confirmPassword: ''
                        })
                        setEmail("")
                        openLogin()
                    } else {
                        alert(resp.message)
                    }
                } else {
                    notification.warning({message: "Please enter both email and password."})
                }
            })
        },

    }


    const loginComponent = (
        <div className="">
            <h4 className="mb-1">Welcome to GBC! 👋</h4>
            <p className="mb-6">

            </p>
            <Form className="mb-6" onSubmit={events.submitFinal}>
                <div className="mb-6 form-password-toggle">
                    <label className="form-label" htmlFor="password">Password</label>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: 'Please input your Password!'
                            }]
                        })(
                            <input placeholder="Password" type="password"
                                   className={'form-control'}
                                   id={'new-password'} name={'new-password'}
                                   autoComplete="new-password"/>
                        )}
                    </Form.Item>
                </div>
                <div className="mb-6 form-password-toggle">
                    <label className="form-label" htmlFor="password">Confirm Password</label>
                    <Form.Item>
                        {getFieldDecorator('confirmPassword', {
                            rules: [{
                                required: true,
                                message: 'Please input your Password!'
                            }]
                        })(
                            <input placeholder="Confirm Password" type="password"
                                   className={'form-control'}
                                   id={'confirm-password'} name={'confirm-password'}
                                   autoComplete="confirm-password"/>
                        )}
                    </Form.Item>
                </div>
                <button type={'submit'} className="btn btn-primary d-grid w-100">
                    Reset Password
                </button>
            </Form>
        </div>
    )


    const openLogin = () => {
        navigate(`/login`);
    }
    return (
        <>
            <div className={'authentication-wrapper authentication-basic container-p-y app-form'}
                 style={{minHeight: "auto"}}>
                <div className="authentication-inner">
                    <div className="card px-sm-6 px-0">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="/" className="app-brand-link gap-2">
                                <span className="app-brand-text demo text-heading fw-bold">
                                </span>
                                </a>
                            </div>


                            {loginComponent}


                            <p className="text-center">
                                <span>Already have an account ?</span>
                                <a onClick={openLogin} className={'hyperLink hover'}>
                                    <span> Login Here</span>
                                </a>
                            </p>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
const WrappedResetPassword = Form.create()(ResetPassword)
export default WrappedResetPassword
