import React, {useEffect, useRef, useState} from "react";
import {Card, displayDate, PopConfirm, Table} from "../../components/Elements/appUtils";

import {Link, useNavigate} from "react-router-dom";
import {resourceListFxn} from "./action";

const {TableCard} = Card;

const ResourceList = () => {
    const navigate = useNavigate();
    const tableRef = useRef();
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const filterInput = [
        {
            key: "name",
            label: "Name",
            span: "col-md-3",
            type: "text",
        },

    ];

    const columns = [
        {title: "Sr. No.", key: "serialNumber", render: (text, record, index) => index + 1,},
        {title: "Name", dataIndex: "name", key: "name"},
        {
            title: 'File', dataIndex: 'document', key: 'document', render: (data, record) => {
                return (
                    <>
                        {data && data.path ?
                            <a className={'btn rounded-pill btn-primary'} href={data.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/>
                            </a> :
                            <a className={'btn rounded-pill btn-label-secondary'}>
                                <i className={'bx bxs-file-pdf opacity-75'}/>
                            </a>}

                    </>
                )
            }
        },
    ];
    const initialColumns = ['serialNumber', 'name', 'document'];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            const regExFilters = ["name" ];
            let data = await resourceListFxn({...params, regExFilters});
            resolve(data);
        });
    };


    return (
        <>
            <TableCard title={`Resource List `} actions={(
                (user.userType=='university')&&<>
                    <a className="btn btn-label-primary" onClick={() => {
                        navigate(`/add-resource`)
                    }}>
                        <span className={'tf-icons bx bx-plus bx-18px me-2'}></span>
                        Add Resource
                    </a>
                </>
            )}>
                <Table
                    filterFields={filterInput}
                    initialColumns={initialColumns}
                    apiRequest={apiRequest}
                    columns={columns}
                    bordered
                    ref={tableRef}
                />
            </TableCard>
        </>
    );
};

export default ResourceList;
