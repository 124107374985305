import React, {useEffect, useState} from "react"
import "../../assests/backApp/css/page-auth.css"
import {Form, notification} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {showLoader, hideLoader} from "../../actions/loader";
import {loginSendOtpFxn, loginSubmitOtpFxn, resendOtpFxn} from "./actions";
import {useNavigate} from "react-router-dom";

let initState = {
    otpScreen: false,
    email: "",
    last2Digit: ""
}
const Login = (props) => {
    const navigate = useNavigate();
    let dispatch = useDispatch()
    let {form} = props;
    const {getFieldDecorator} = form;
    let [state, setState] = useState(initState);
    const [otpValues, setOtpValues] = useState(["", "", "", ""]);

    useEffect(() => {
        console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }, [])

    let events = {
        submitFinal: (e) => {
            e.preventDefault();
            form.validateFields(async (err, values) => {
                if (!err) {
                    let x = await dispatch(loginSendOtpFxn(values));
                    if (x && x.success) {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                otpScreen: true,
                                email: values.email,

                            }
                        })
                    }
                } else {
                    notification.warning({message: "Please enter both email and password."})
                }
            })
        },
        handleOtpChange: (index, value) => {
            if (!isNaN(value) && value.length < 2) {
                const updatedOtp = [...otpValues];
                updatedOtp[index] = value;
                setOtpValues(updatedOtp);

                if (value && index < otpValues.length - 1) {
                    const nextInput = document.getElementById(`otp-input-${index + 1}`);
                    if (nextInput) {
                        nextInput.focus();
                    }
                } else if (!value && index > 0) {
                    const prevInput = document.getElementById(`otp-input-${index - 1}`);
                    if (prevInput) {
                        prevInput.focus();
                    }
                }
            }
        },
        submitOtp: (e) => {
            const {form} = props;
            e.preventDefault();
            form.validateFields(async (err, values) => {
                const otp = otpValues.join("");
                if (otp.trim() === "") {
                    notification.error({
                        message: "Please enter the OTP.",
                    });
                    return;
                }
                values.otp = otp;
                values.email = state.email;
                let x = await dispatch(loginSubmitOtpFxn(values));

                if (!x.error) {
                    localStorage.setItem("token", x.token);
                    localStorage.setItem("user", JSON.stringify(x.user));
                    dispatch({
                        type: "SET_CURRENT_USER",
                        user: x.user,
                    });
                    setTimeout(() => {
                        window.location.href = "/dashboard";
                    }, 300);
                } else {
                    // notification.error({
                    //     message: x.message,
                    // });
                }
            })
        },
        resendOtpFnc: (e) => {
            const {form} = props;
            e.preventDefault();
            form.validateFields(async (err, values) => {
                const otp = otpValues.join("");


                values.email = state.email;

                let x = await dispatch(resendOtpFxn(values));

                if (!x.error) {
                    notification.success({
                        message: x.message,
                    });
                } else {
                    notification.error({
                        message: x.message,
                    });
                }
            })
        }
    }

    const openForgotPassword = () => {
        navigate(`/forgot-password`);
    }

    const loginComponent = (
        <div className="">
            <h4 className="mb-1">Welcome to GBC! 👋</h4>
            <p className="mb-6">

            </p>
            <Form className="mb-6" onSubmit={events.submitFinal}>
                <div className="mb-6">
                    <label htmlFor="email" className="form-label">Email</label>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: 'Please input your email address!'
                            }]
                        })(
                            <input placeholder="Enter your email" type={'email'}
                                   className={'form-control'}
                                   id={'new-email'} name={'new-email'}
                                   autoComplete="new-email"/>
                        )}
                    </Form.Item>
                </div>
                <div className="mb-6 form-password-toggle">
                    <label className="form-label" htmlFor="password">Password</label>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: 'Please input your Password!'
                            }]
                        })(
                            <input placeholder="Password" type="password"
                                   className={'form-control'}
                                   id={'new-password'} name={'new-password'}
                                   autoComplete="new-password"/>
                        )}
                    </Form.Item>
                </div>
                <div className="my-8">
                    <div className="d-flex justify-content-between">
                        <div className="form-check mb-0 ms-2">
                            <input className="form-check-input" type="checkbox" id="remember-me"/>
                            <label className="form-check-label" htmlFor="remember-me">
                                Remember Me
                            </label>
                        </div>
                        <a onClick={openForgotPassword} className={'hyperLink hoverable'}>
                            <p className="mb-0">Forgot Password?</p>
                        </a>
                    </div>
                </div>
                <button type={'submit'} className="btn btn-primary d-grid w-100">
                    Sign in
                </button>
            </Form>
        </div>
    )

    const otpComponent = (
        <div className="">
            <h4 className="mb-1">Two Step Verification 💬</h4>
            <p className="text-start mb-6">
                We sent a verification code to your email address. Enter the code from the email address in the field
                below.
                <span className="fw-medium d-block mt-1 text-heading">********{state.last2Digit}</span>
            </p>
            <p className="mb-0">Type your 4 digit security code</p>
            <Form onSubmit={events.submitOtp}>
                <div className="mb-4">
                    <div
                        className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                        {otpValues.map((item, index) => {
                            return (
                                <input type="tel"
                                       autoFocus={index == 0 ? true : false}
                                       key={index}
                                       onChange={(e) => events.handleOtpChange(index, e.target.value)}
                                       id={`otp-input-${index}`}
                                       type={'password'}
                                       className="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                       maxLength={1}/>
                            )
                        })}
                    </div>
                    <input type="hidden" name="otp"/>
                </div>

                <button className="btn btn-primary d-grid w-100 mb-6" type={'submit'}>
                    Verify my account
                </button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <button onClick={events.resendOtpFnc} className="btn btn-default">Resend Otp</button>
                </div>
            </Form>
        </div>
    )

    const openSignup = () => {
        navigate(`/register`);
    }
    return (
        <>
            <div className={'authentication-wrapper authentication-basic container-p-y app-form'}
                 style={{minHeight: "auto"}}>
                <div className="authentication-inner">
                    <div className="card px-sm-6 px-0">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="/" className="app-brand-link gap-2">
                                <span className="app-brand-text demo text-heading fw-bold">
                                </span>
                                </a>
                            </div>


                            {state.otpScreen ? otpComponent : loginComponent}


                            <p className="text-center">
                                <span>New on our platform ? </span>
                                <a onClick={openSignup} className={'hyperLink hover'}>
                                    <span> Create an account</span>
                                </a>
                            </p>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
const WrappedLogin = Form.create()(Login)
export default WrappedLogin
