import React, {useState, useRef, useEffect} from 'react';
import {
    Table,
    PopConfirm,
    Card,
    displayDate,
    Avatar,
    Tooltip, notification, Modal
} from '../../components/Elements/appUtils';
import {
    applicationListFxn,
    generateLoaFxn,
    reGenerateOfferLetterFxn,
    updateApplicationStatusFxn,
    generateContractFxn,
    generateOfferLetterFxn,
    palRequestFxn,
    checkDocumentRequirementFxn,
    uploadPalFxn, generateCo_opFxn
} from "./actions";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import GenerateInvoiceDrawer from "../Invoices/add";
import AgentEditModal from "./modals/agentEditModal";
import InvoiceModal from "./modals/invoiceModal";

import CourseEditModal from "./modals/courseEditModal";
import StudentAction from "./components/studentAction";
import IntakeEditModal from "./modals/intakeEditModal";
import {courseListFxn} from "../course/action";
import UserRightComponent from "../../userRights";
import AddMoreAppModal from "./modals/addMoreAppModal";
import {uploadDocumentFxn, uploadPalDocumentFxn} from "../../components/profileComponent/action";
import InputBox from "../../components/Elements/InputBox";
import OfferLetterGenerateModal from "./modals/offerLetterGenerate";

let {TableCard} = Card


const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const StudentList = (props) => {
    let {currentUser} = props;
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [appId, setAppId] = useState('');
    const [stdId, setStdId] = useState('');
    const [offerLetterState, setOfferLetterState] = useState({
        visible: false,
        applicationId: "",
        scholarship: false,
        scholarshipAmount: undefined,
        scholarshipPercent: undefined,
    })
    const handleFileChange = (e) => {
        setShowModal(true)
        const file = e.target.files[0];
        if (file) {
            setPalLetter(file)
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setImageSrc({})
    };
    const handleOpen = (record) => {

        setShowModal(true);
        setStdId(record.studentId)
        setAppId(record._id)
        tableRef.current.reload()

    };
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

    const [withdraw, setWithdraw] = useState({
        value: false,
        appId: ""
    });


    const dispatch = useDispatch();
    const [total, setTotal] = useState(0)
    const [invoiceDrawer, setInvoiceDrawer] = useState({
        applicationId: '',
        visible: false,
        scholarship: false,
        scholarshipPercent: undefined,
    })
    const [invoiceModalState, setInvoiceModalState] = useState({
        applicationId: '',
        visible: false,
        application: ''
    })
    const [noteModal, setNoteModal] = useState({
        applicationId: '',
        studentId: '',
        visible: false
    })
    const [editAgentModal, setEditAgentModal] = useState({
        agentId: "",
        visible: false,
        applicationId: ''
    })
    const [editCourseModal, setEditCourseModal] = useState({
        courseId: "",
        visible: false,
        applicationId: '',
        record: {}
    })
    const [addMoreModel, setAddMoreModel] = useState({

        visible: false,
        studentId: '',
        record: {}
    })
    const [editIntakeModal, setEditIntakeModal] = useState({
        visible: false,
        application: {}
    })

    const [imageSrc, setImageSrc] = useState({});
    const [palLetter, setPalLetter] = useState({});
    const events = {
        showInvoiceDrawer: async (record) => {
            setInvoiceDrawer({
                applicationId: record._id,
                visible: true,
                scholarship: record.scholarship,
                scholarshipPercent: record.scholarshipPercent
            })
            tableRef.current.reload()

        },
        hideInvoiceDrawer: async () => {
            setInvoiceDrawer({
                applicationId: '',
                visible: false,
                scholarship: false,
                scholarshipPercent: undefined,
            })
            tableRef.current.reload()

        },
        hideEditAgentModal: async () => {
            setEditAgentModal({
                agentId: "",
                visible: false,
                applicationId: ""
            })
        },
        hideEditCourseModal: async () => {
            setEditCourseModal({
                agentId: "",
                visible: false,
                applicationId: ""
            })
        },
        hideEditIntakeModal: async () => {
            setEditIntakeModal({
                visible: false,
                application: {}
            })
        },
        hideAddMoreAppModal: async () => {
            setAddMoreModel({
                agentId: "",
                visible: false,
                studentId: ""
            })
        },
        handleGenerateLoa: async (applicationId, studentId) => {
            let respDoc = await dispatch(checkDocumentRequirementFxn({
                applicationId: applicationId,
                studentId: studentId,
            }))
            if (respDoc && respDoc.error) {
                return
            }

            const resp = await dispatch(generateLoaFxn({applicationId}));
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        handleGenerateCo_opLetter: async (applicationId, studentId) => {

            const resp = await dispatch(generateCo_opFxn({applicationId}));
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        showInvoiceModal: async (application) => {
            setInvoiceModalState({
                applicationId: application._id,
                visible: true,
                application: application,
            })
        },
        hideInvoiceModal: async () => {
            setInvoiceModalState({
                applicationId: '',
                visible: false,
                application: ''
            })
        },
        tableReload: () => {
            if (tableRef.current)
                tableRef.current.reload();
        },
        handleGenerateContract: async (applicationId) => {
            const resp = await dispatch(generateContractFxn({applicationId}));
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        handleGenerateOfferLetter: async (applicationId) => {
            const resp = await dispatch(generateOfferLetterFxn({applicationId}));
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        reGenerateOffer: async (applicationId) => {
            let resp = await dispatch(reGenerateOfferLetterFxn({applicationId}))
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        showOfferLetterModal: (record) => {
            setOfferLetterState({
                visible: true,
                applicationId: record._id,
                scholarship: record.scholarship || false,
                scholarshipPercent: record?.scholarshipPercent || undefined,
                scholarshipAmount: record?.scholarshipAmount || undefined,
            })
        },
        hideOfferLetterModal: (record) => {
            setOfferLetterState({
                visible: false,
                applicationId: "",
                scholarship: false,
                scholarshipPercent: undefined,
                scholarshipAmount: undefined,
            })
        },

        handleSubmits: async (e) => {
            e.preventDefault()
            /*     let respDoc = await dispatch(checkDocumentRequirementFxn({
                     applicationId: appId,
                     studentId: stdId,
                 }))
                 if (respDoc && respDoc.error) {
                     return
                 }*/

            if (!palLetter || (palLetter && !palLetter.name)) {
                notification.warning({
                    message: 'Please choose PAL'
                });
                return
            }

            let fd = new FormData();
            let obj = {}
            obj.type = 'PAL'
            obj.applicationId = appId;
            obj.studentId = stdId;
            fd.append('obj', JSON.stringify(obj));
            if (palLetter && palLetter.name) {
                fd.append('palLetter', palLetter)
            }
            let resp = await dispatch(uploadPalFxn(fd));
            if (resp && resp.success) {
                setShowModal(false)
                setPalLetter({})
                tableRef.current.reload()
            }
        },
        requestForPalFxn: async (data) => {
            let resp = await dispatch(palRequestFxn({applicationId: data._id}));
            if (resp && resp.success) {
                events.tableReload();
            }
        }
    }


    const tableRef = useRef();

    const initialState = {
        companyName: "",
        firstName: "",
        courseName: "",
        intake: "",
        isEsl: "",
        startDate: "",
        endDate: "",
        expirationDate: "",
        issueDate: "",
        marketingManagerName: "",
        studentType: "",
        studentId: "",
        student: "",
        status: "",
    };
    const [studentDetails, setStudentDetails] = useState(initialState);

    const handleInputChange = (name, e) => {
        const {value} = e.target;
        setStudentDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleWithdraw = async (applicationId) => {
        let resp = await dispatch(updateApplicationStatusFxn({applicationId, withdraw: true}))
        if (resp && resp.success) {
            events.tableReload();
        }
    }

    const [courseList, setCourseList] = useState([]);
    const listEvents = {
        loadCourseList: async () => {
            try {
                let params = {
                    count: 50,
                    results: 50
                }
                const {data} = await courseListFxn({params});
                setCourseList(data.data);
            } catch (error) {
                console.error("Failed to load course list:", error);
            }
        },
    }
    useEffect(() => {
        listEvents.loadCourseList();
    }, [])
    const filterInput = [
        {
            key: "studentId",
            label: "Student Id",
            style: {'height': '20px'},
            span: "col-md-3",
            type: "text"
        },
        {
            key: "status",
            label: " Status",
            span: "col-md-3",
            type: "select",
            options: ['Waiting To Apply', 'withdraw']

        },
        {
            key: "firstName",
            label: "First Name",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "courseId",
            label: "Course Name",
            span: "col-md-3",
            type: "select",
            options: courseList.map((course) => ({
                label: course.courseName,
                value: course._id,
            })),
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label,
        },
        {
            key: "intake",
            label: "Intake",
            span: "col-md-3",
            type: "select",
            options: months,
        },
        {
            key: "studentType",
            label: "Student Type",
            type: "select",
            options: [
                {label: "International", value: "international"},
                {label: "Onshore", value: "onshore"},
            ],
            span: "col-md-3",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label
        },
    ];


    const columns = [
        {
            title: 'Sr. No.', key: 'serialNumber', render: (text, record, index) => {
                return (
                    <>
                        {index + 1}.
                    </>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (name, record) => {
                return (
                    <>
                        <StudentAction record={record}/>
                    </>
                )
            }
        },
        {
            title: 'Student Id', dataIndex: 'studentIdNo',
            key: 'studentIdNo'
        },
        {
            title: 'Company Name', dataIndex: 'companyName',
            key: 'companyName'
        },
        {
            title: 'Course', dataIndex: 'courseCode', key: 'courseCode',
            render: (text) => {
                return (
                    <>
                        {text ? <span className={'btn rounded-pill btn-outline-secondary btn-sm'}>{text}</span> : null}
                    </>
                )
            }
        },
        {
            title: 'Intake',
            dataIndex: 'intake',
            key: 'intake',
            render: (item) => item && item.month && item.year ? `${item.month}, ${item.year}` : ''
        },
        {title: 'ESL', dataIndex: 'isEsl', key: 'isEsl', render: (isEsl) => isEsl ? 'Yes' : 'No'},
        {
            title: 'Offer Letter',
            dataIndex: 'offerLetter',
            key: 'offerLetter',
            render: (data, record) => {
                return (
                    <>
                        {data && data.path ? (
                            <a className={'btn rounded-pill btn-primary'} href={data.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/>
                            </a>
                        ) : (
                            <a className={'btn rounded-pill btn-label-secondary'}>
                                <i className={'bx bxs-file-pdf opacity-75'}/>
                            </a>
                        )}

                        {user.userType === 'university' ? (
                            <UserRightComponent route={{right: 'student', rightType: 'generate'}}>
                                <div className={'mt-2'}>
                                    <a className={'btn rounded-pill btn-outline-primary btn-xs'} onClick={() => {
                                        events.showOfferLetterModal(record)
                                    }}>
                                        {data && data.path ? "Re-Generate" : "Generate"}
                                    </a>
                                </div>
                            </UserRightComponent>
                        ) : null}
                    </>
                );
            }
        },
        {
            title: 'Pal Letter', key: 'palLetter', dataIndex: 'palLetter', render: (data, record) =>
                <>
                    {record.palRequested ? <div className={'mb-2'}>


                        {data && data.path ? (
                            <a className={'btn rounded-pill btn-primary'} href={data.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/>
                            </a>
                        ) : (
                            <>
                                {/*       <a className={'btn rounded-pill btn-label-secondary'}>
                                    <i className={'bx bxs-file-pdf opacity-75'}/>
                                </a>*/}
                                <div className={'fontsize-xxs mb-1 text-dark'}>
                                    Pal Requested
                                </div>
                            </>
                        )}
                    </div> : null}


                    {record.palRequested ?
                        <>
                            {user.userType === 'university' ? (
                                <UserRightComponent route={{right: 'student', rightType: 'generate'}}>
                                    <div className={'mt-2'}>
                                        <a className='btn rounded-pill btn-outline-primary btn-xs' onClick={() => {
                                            handleOpen(record)
                                        }}>
                                            {data && data.path ? "Re-Upload" : "Upload"}
                                        </a>
                                    </div>
                                </UserRightComponent>
                            ) : null}
                        </> :
                        <>
                            <PopConfirm
                                title="Are you sure, you want to Request for PAL?"
                                ref={tableRef}
                                onConfirm={(e) => {
                                    events.requestForPalFxn(record)
                                }}
                            >
                                <a className='btn rounded-pill btn-outline-primary btn-xs'>
                                    Request For PAL
                                </a>
                            </PopConfirm>
                        </>
                    }


                </>
        },

        {
            title: 'LOA Letter', dataIndex: 'loaLetter', key: 'loaLetter', render: (data, record) => {
                return (
                    <>
                        {data && data.path ?
                            <a className={'btn rounded-pill btn-primary'} href={data.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/>
                            </a> :
                            <a className={'btn rounded-pill btn-label-secondary'}>
                                <i className={'bx bxs-file-pdf opacity-75'}/>
                            </a>}

                        {record.status !== 'withdraw' && user.userType == 'university' ?
                            <UserRightComponent route={{right: "student", rightType: "generate"}}>
                                <div className={'mt-2'}>
                                    <PopConfirm
                                        onConfirm={() => {
                                            if (record.status !== 'withdraw')
                                                events.handleGenerateLoa(record._id, record.studentId)
                                        }}
                                        title="Are you sure you want to generate LOA?">
                                        <a className={'btn rounded-pill btn-outline-primary btn-xs'}>Generate</a>
                                    </PopConfirm>
                                </div>
                            </UserRightComponent> : null}
                    </>
                )
            }
        },
      /*  {
            title: 'Co-op Letter', dataIndex: 'co_opLetter', key: 'co_opLetter', render: (data, record) => {
                return (
                    <>
                        {data && data.path ?
                            <a className={'btn rounded-pill btn-primary'} href={data.path} target={'_blank'}>
                                <i className={'bx bxs-file-pdf'}/>
                            </a> :
                            <a className={'btn rounded-pill btn-label-secondary'}>
                                <i className={'bx bxs-file-pdf opacity-75'}/>
                            </a>}

                        {record.status !== 'withdraw' && user.userType == 'university' ?
                            <UserRightComponent route={{right: "student", rightType: "generate"}}>
                                <div className={'mt-2'}>
                                    <PopConfirm
                                        onConfirm={() => {
                                            if (record.status !== 'withdraw')
                                                events.handleGenerateCo_opLetter(record._id, record.studentId, record.courseId, record.courseName)
                                        }}
                                        title="Are you sure you want to generate CO-OP Letter?">
                                        <a className={'btn rounded-pill btn-outline-primary btn-xs'}>Generate</a>
                                    </PopConfirm>
                                </div>
                            </UserRightComponent> : null}
                    </>
                )
            }
        },*/
        {title: 'Start Date', dataIndex: 'startDate', key: 'startDate', render: (startDate) => displayDate(startDate)},
        {title: 'Completion Date', dataIndex: 'endDate', key: 'endDate', render: (endDate) => displayDate(endDate)},
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (expirationDate) => displayDate(expirationDate)
        },
        {title: 'Issue Date', dataIndex: 'issueDate', key: 'issueDate', render: (issueDate) => issueDate},
        {title: 'Marketing Manager', dataIndex: 'marketingManagerName', key: 'marketingManagerName'},

        {
            title: 'Invoice', dataIndex: 'invoice', key: 'invoice', width: 100, render: (data, record) => {
                return (
                    <>
                        <div onClick={() => {
                            events.showInvoiceModal(record)
                        }}>
                            <Tooltip title={'View Invoice'}>
                                {record && record.invoiceCount ?
                                    <a className={'btn rounded-pill btn-primary'} target={'_blank'}>
                                        <i className={'bx bxs-detail'}/>
                                    </a> :
                                    <a className={'btn rounded-pill btn-label-secondary'}>
                                        <i className={'bx bxs-detail opacity-50'}/>
                                    </a>
                                }
                            </Tooltip>
                        </div>
                        <UserRightComponent route={{right: "accounts", rightType: "generate"}} userTypes={['team']}>
                            <div className={'mt-2'}>
                                <a className={'btn rounded-pill btn-outline-primary btn-xs'}
                                   onClick={() => {
                                       if (record.status !== 'withdraw')
                                           events.showInvoiceDrawer(record)
                                   }}>Generate</a>
                            </div>
                        </UserRightComponent>

                    </>
                )
            }
        }
        ,

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (data, record) => (
                <UserRightComponent route={{right: "student", rightType: "edit"}} name={'edit student'}>
                    {actionMenu(record)}
                </UserRightComponent>)
        }
    ];


    const initialColumns = [
        'serialNumber', 'companyName', 'firstName', 'courseCode', 'intake', 'issueDate', 'marketingManagerName', 'createdAt', 'action', 'loaLetter', 'invoice', 'palLetter', 'studentIdNo', 'offerLetter'
    ];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(applicationListFxn(params));
            setTotal(resp.total);
            resolve(resp);
        });
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    };

    let menuArr = [
        {
            name: "Edit Student", onClick: (record) => {
                navigate(`/edit-student/${record.studentId}`)
            }
        },
        {
            name: "Change Agent", onClick: (record) => {
                setEditAgentModal({agentId: record.agentId, visible: true, applicationId: record._id})
            }
        },
        {
            name: "Change Course", onClick: (record) => {
                if (!record.invoiceCount) {
                    setEditCourseModal({
                        courseId: record.courseId,
                        visible: true,
                        applicationId: record._id,
                        record: record
                    })
                } else {
                    notification.warning({message: "Invoice generated . Course changes are no longer allowed"});
                }
            }
        },
        {
            name: "Add Application", onClick: (record) => {
                setAddMoreModel({
                    visible: true,
                    studentId: record.studentId,
                    record: record
                })
            }
        },
        {
            name: "Change Intake", onClick: (record) => {
                if (!record.invoiceCount) {
                    setEditIntakeModal({
                        visible: true,
                        application: record
                    })
                } else {
                    notification.warning({message: "Invoice generated . Intake changes are no longer allowed"});
                }
            }
        },
        {
            name: "Generate Contract", onClick: (record) => {
                Modal.confirm({
                    title: 'Are you sure you want to generate a contract?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onSubmit: () => {
                        events.handleGenerateContract(record._id);
                    }
                });
            }
        },
        {
            name: "hr", component: (
                <li>
                    <hr className="dropdown-divider"/>
                </li>
            )
        },
        {
            name: "Withdrawal", onClick: (record) => {
                Modal.confirm({
                    title: 'Are you sure, you want to withdrawal application ?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onSubmit: () => {
                        handleWithdraw(record._id)
                    }
                });
            }
        },


    ]


    const actionMenu = (record) => {
        return (
            <div className="btn-group">
                <a data-bs-toggle="dropdown" aria-expanded="false" className={'cursor-pointer'}>
                    <i className="bx bx-dots-vertical-rounded"/>
                </a>
                <ul className="dropdown-menu dropdown-menu-end student-action-dropdown-menu lh-12">
                    {menuArr.map((item) => {
                        return (
                            item.component ?
                                item.component :
                                <li>
                                    <a className="dropdown-item cursor-pointer text-black"
                                       onClick={() => item.onClick(record)}>
                                        <i className="bx bx-chevron-right scaleX-n1-rtl"></i>
                                        {item.name}
                                    </a>
                                </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
    const borderStyle = imageSrc.file ? '2px dashed blue' : '2px dashed #ccc';

    return (
        <>
            <TableCard title={`Student List (Total: ${total})`}
                       actions={
                           <>
                               <UserRightComponent route={{right: "student", rightType: "add"}} name={'add student'}>
                                   <a className="btn btn-label-primary" onClick={() => navigate(`/add-student`)}>
                                       <span className={'tf-icons bx bx-plus bx-18px me-2'}></span>
                                       Add Student
                                   </a>
                               </UserRightComponent>
                           </>
                       }>
                <Table
                    initialColumns={initialColumns}
                    filterFields={filterInput}
                    apiRequest={apiRequest}
                    columns={columns} bordered ref={tableRef}/>
            </TableCard>
            {invoiceDrawer.visible ? (
                <GenerateInvoiceDrawer
                    {...invoiceDrawer}
                    applicationId={invoiceDrawer.applicationId}
                    onClose={() => events.hideInvoiceDrawer()}
                    visible={invoiceDrawer.visible}
                />
            ) : null}


            {invoiceModalState.visible ?
                <InvoiceModal
                    onClose={events.hideInvoiceModal}
                    {...invoiceModalState} events={events}/> : null}
            {editAgentModal.visible ?
                <AgentEditModal
                    onClose={events.hideEditAgentModal}
                    onSubmit={() => {
                        events.hideEditAgentModal();
                        events.tableReload()
                    }}
                    {...editAgentModal}/> : null}
            {editCourseModal.visible ?
                <CourseEditModal
                    onClose={events.hideEditCourseModal}
                    {...editCourseModal}
                    onSubmit={() => {
                        events.hideEditCourseModal();
                        events.tableReload()
                    }}
                /> : null}
            {editIntakeModal.visible ?
                <IntakeEditModal
                    onClose={events.hideEditIntakeModal}
                    {...editIntakeModal}
                    onSubmit={() => {
                        events.hideEditIntakeModal();
                        events.tableReload()
                    }}
                /> : null}
            {addMoreModel.visible ?
                <AddMoreAppModal
                    onClose={events.hideAddMoreAppModal}
                    {...addMoreModel} tableReload={events.tableReload}/> : null}
            {showModal ? <Modal
                className="custom-ant-modal"
                visible={showModal}
                onClose={handleCancel}
                width={'30%'}
                placement='right'
                title={'Upload PAL'}>
                <InputBox title={'Choose PAL'}>
                    <input type={'file'}
                           name={'palLetter'} id={'palLetter'}
                           className={'form-control'} onChange={(e) => handleFileChange(e)}
                    />
                </InputBox>

                <div className={'btn-box mt-5'}>
                    <button type="button" className="btn btn-label-danger btn-md"
                            onClick={handleCancel}
                    ><span>No</span></button>
                    <button type="button" className="btn btn-label-primary btn-md"
                            onClick={(e) => {
                                events.handleSubmits(e)
                            }}><span>Yes</span>
                    </button>
                </div>
            </Modal> : null}


            {offerLetterState.visible ?
                <OfferLetterGenerateModal
                    {...offerLetterState}
                    onClose={events.hideOfferLetterModal}
                    onSubmit={() => {
                        events.hideOfferLetterModal();
                        tableRef.current.reload()
                    }}
                /> : null}
        </>
    );
};

export default StudentList;
