import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CountryJson from '../../assests/jsonFiles/countries.json';
import StatesJson from "../../assests/jsonFiles/states.json";
import NationalitiesJson from "../../assests/jsonFiles/nationalities.json";
import {Card, dateFormat, Form, notification} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {courseListFxn} from "../course/action";
import {getAllAgentFxn} from "../manager/action";
import _ from "lodash";
import {addStudentFxn} from "./actions";
import moment from "moment";
import {conditionOptions} from "../../components/_utils/_utils";

const initState = {
    firstName: "",
    lastName: "",
    dateOfBirth: undefined,
    email: "",
    nationality: "",
    studentType: "",
    course: "",
    intake: "",
    isEsl: "",
    startDate: undefined,
    endDate: undefined,
    expirationDate: undefined,
    issueDate: moment().format(dateFormat),
    agent: "",
    marketingManager: "",
    countryName: "",
    stateName: "",
    cityName: "",
    address: "",
    postalCode: "",
};

const AddStudent = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue}, currentUser: user
    } = props;
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const [courseList, setCourseList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [marketingManagerList, setMarketingManagerList] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [intakeList, setIntakeList] = useState([])
    let [intake, setIntake] = useState([])

    const listEvents = {
        loadCourseList: async () => {
            try {
                let params = {
                    count: 50,
                    results: 50
                }
                const {data} = await courseListFxn({params});
                setCourseList(data.data);
            } catch (error) {
                console.error("Failed to load course list:", error);
            }
        },
        loadAgentList: async () => {
            let obj = {
                results: 500,
                count: 500,
                userType: "agent"
            }
            let resp = await getAllAgentFxn(obj);
            setAgentList(resp.data);
        }
    }

    useEffect(() => {
        events.loadAgentFxn()
    }, [agentList])


    const events = {
        loadAgentFxn: () => {
            if (user.userType == "agent" && agentList && agentList.length) {
                let singleAgent = agentList[0]
                setFieldsValue({agentId: singleAgent._id})
                setTimeout(() => {
                    events.chooseAgent(singleAgent._id)
                }, 200)
            }
        },
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        loadState: (countryId) => {
            if (countryId) {
                let filterArr = _.filter(StatesJson, (item) => item.country_id === countryId);
                return filterArr;
            }
            return [];
        },
        chooseCountry: (value) => {
            let country = _.find(CountryJson, (item) => item.name === value);
            if (country) {
                setFieldsValue({countryName: value})
                setAllStates(country.id ? events.loadState(country.id) : []);
            }
        },
        chooseState: (value) => {
            setFieldsValue({stateName: value})
        },
        chooseCourse: (courseId) => {
            let findCourse = _.find(courseList, (item) => {
                return item && item._id == courseId
            })
            if (findCourse) {
                let data = []
                _.each(findCourse.intakeList, (item) => {
                    if (item && item.active) {
                        item.value = `${item.month}-${item.year}`
                        item.label = `${item.month}, ${item.year}`
                        data.push(item)
                    }
                })
                setIntakeList(data)
            }
            setFieldsValue({courseId: courseId})
        },
        chooseIntake: (value) => {
            let findIntake = _.find(intakeList, (item) => {
                return item && item.value == value
            })
            if (findIntake) {
                setFieldsValue({
                    intakeTemp: value,
                    startDate: findIntake.startDate ? findIntake.startDate : undefined,
                    endDate: findIntake.endDate ? findIntake.endDate : undefined,
                    expirationDate: findIntake.startDate ? moment(findIntake.startDate, dateFormat).add(15, 'day').format(dateFormat) : undefined,
                })
            }
            if (value) {
                let intakeVal = value.split('-')
                let intake = {
                    month: intakeVal[0],
                    year: intakeVal[1]
                }
                setIntake(intake)
            } else {
                setIntake({})
            }
        },
        chooseAgent: (data) => {
            let obj = {
                agentId: data
            }
            let findAgent = _.find(agentList, (item) => {
                return item && item._id == data
            })
            if (findAgent && findAgent.marketingManager) {
                obj.marketingManagerId = findAgent.marketingManager.userId
                setMarketingManagerList([findAgent.marketingManager])
            }
            setFieldsValue({
                ...obj
            })
        },
        setDefaultFields: () => {
            setFieldsValue({
                issueDate: moment().format(dateFormat)
            })
        }
    };

    const sections = [
        {
            title: "Personal Information",
            fields: [
                {
                    key: "firstName",
                    label: "First Name",
                    span: "col-md-6",
                    placeholder: "Enter First Name",
                    required: true
                },
                {
                    key: "lastName",
                    label: "Last Name",
                    span: "col-md-6",
                    placeholder: "Enter Last Name",
                },
                {
                    key: "dateOfBirthString",
                    label: "Date of Birth (DD/MM/YYYY)",
                    type: "dateOfBirth",
                    span: "col-md-6",
                    placeholder: "Enter Date of Birth (DD/MM/YYYY)",
                    required: true,
                    onChange: (value) => {
                        setFieldsValue({
                            dateOfBirthString: value
                        })
                    },
                },
                {
                    key: "email",
                    label: "Email",
                    type: "email",
                    span: "col-md-6",
                    placeholder: "Enter Email",
                    required: true
                },
                {
                    key: "nationality",
                    label: "Nationality",
                    span: "col-md-6",
                    placeholder: "Enter Nationality",
                    required: true,
                    type: "select",
                    showSearch: true,
                    options: NationalitiesJson,
                    onChange: (value) => {
                        setFieldsValue({
                            nationality: value
                        })
                    },
                },
                {
                    key: "gender",
                    label: "Gender",
                    type: "select",
                    required: true,
                    options: [
                        {label: "Male", value: "male"},
                        {label: "Female", value: "female"},
                        {label: "Non Binary", value: "non-binary"},
                    ],
                    span: "col-md-6",
                    placeholder: "Select Gender",
                    keyAccessor: (option) => option.value,
                    valueAccessor: (option) => option.label,
                    onChange: (value) => {
                        setFieldsValue({
                            gender: value
                        })
                    },
                },
                {
                    key: "studentType",
                    label: "Student Type",
                    type: "select",
                    required: true,
                    options: [
                        {label: "International", value: "international"},
                        {label: "Onshore", value: "onshore"},
                    ],
                    span: "col-md-6",
                    placeholder: "Select Student Type",
                    keyAccessor: (option) => option.value,
                    valueAccessor: (option) => option.label,
                    onChange: (value) => {
                        setFieldsValue({
                            studentType: value
                        })
                    },
                },
                {
                    key: "mobile",
                    type: "phone",
                    label: "Phone no",
                    required: true,
                    placeholder: "Enter mobile no",
                    span: 'col-md-6',
                    onChange: (value) => {
                        setFieldsValue({mobile: value});
                    }
                },
                {
                    key: "address",
                    label: "Address",
                    span: "col-md-6",
                    placeholder: "Enter Address",
                },
                {
                    key: "countryName",
                    type: "select",
                    label: "Country",
                    placeholder: "Select Country",
                    span: "col-md-4",
                    required: true,
                    showSearch: true,
                    options: CountryJson.map((country) => country.name),
                    onChange: (value) => {
                        events.chooseCountry(value)
                    },
                },
                {
                    key: "stateName",
                    type: "select",
                    label: "State",
                    placeholder: "Select State",
                    span: "col-md-4",
                    showSearch: true,
                    options: allStates.map((state) => state.name),
                    onChange: (value) => {
                        setFieldsValue({
                            stateName: value
                        })
                    },
                },
                {
                    key: "cityName",
                    label: "City",
                    span: "col-md-4",
                    placeholder: "Enter City",
                },
                {
                    key: "postalCode",
                    label: "Postal Code",
                    span: "col-md-4",
                    placeholder: "Enter PostalCode"
                },

            ],
        },
        {
            title: "Program Information",
            fields: [
                {
                    key: "courseId",
                    label: "Course",
                    type: "select",
                    required: true,
                    options: courseList.map((course) => ({
                        label: course.courseName,
                        value: course._id,
                    })),
                    span: "col-md-6",
                    placeholder: "Select Course",
                    keyAccessor: (option) => option.value,
                    valueAccessor: (option) => option.label,
                    onChange: (value) => {
                        events.chooseCourse(value)
                    },
                },
                {
                    key: "intakeTemp",
                    label: "Intake",
                    type: "select",
                    options: intakeList,
                    span: "col-md-6",
                    placeholder: "Select Intake",
                    required: true,
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.label}`,
                    onChange: (x) => {
                        events.chooseIntake(x)
                    }
                },
                {
                    key: "isEsl",
                    label: "ESL required for this course",
                    type: "radio",
                    required: true,
                    options: conditionOptions,
                    span: "col-md-3",
                    keyAccessor: (option) => option.value,
                    valueAccessor: (option) => option.name,
                    onChange: (value) => {
                        setFieldsValue({
                            isEsl: value
                        })
                    },
                },
                {
                    key: "scholarship",
                    label: "Do you want to add a scholarship?",
                    type: "radio",
                    required: true,
                    options: conditionOptions,
                    span: "col-md-3",
                    keyAccessor: (option) => option.value,
                    valueAccessor: (option) => option.name,
                    onChange: (value) => {
                        setFieldsValue({
                            scholarship: value
                        });
                    },
                },
                {
                    key: "issueDate",
                    label: "Issue Date  (DD/MM/YYYY)",
                    required: true,
                    type: "customDate",
                    span: "col-md-6",
                    placeholder: "Choose Issue Date",
                    onChange: (value) => {
                        setFieldsValue({
                            issueDate: value
                        })
                    },
                },
                {
                    key: "startDate",
                    label: "Start Date",
                    type: "customDate",
                    span: "col-md-4",
                    placeholder: "Start Date",
                    disabled: true,
                },
                {
                    key: "endDate",
                    label: "Completion Date",
                    type: "customDate",
                    span: "col-md-4",
                    placeholder: "Completion Date",
                    disabled: true,
                },
                {
                    key: "expirationDate",
                    label: "Expiration Date",
                    type: "customDate",
                    span: "col-md-4",
                    placeholder: "Choose Expiration Date",
                    disabled: true,
                    onChange: (value) => {
                        setFieldsValue({
                            expirationDate: value
                        })
                    },
                },

            ],
        },
        {
            title: user.userType == "agent" ? "BDM Details" : "Agent Details",
            fields: [
                {
                    key: "agentId",
                    label: "Agent",
                    type: "select",
                    options: agentList,
                    span: "col-md-6",
                    placeholder: "Select Agent",
                    required: true,
                    hidden: user.userType == "agent",
                    keyAccessor: (option) => option._id,
                    valueAccessor: (option) => `${option.name} (${option.companyName})`,
                    onChange: (value) => {
                        events.chooseAgent(value)
                    },
                },
                {
                    key: "marketingManagerId",
                    label: "Marketing Manager",
                    type: "select",
                    options: marketingManagerList,
                    span: "col-md-6",
                    disabled: true,
                    placeholder: "Select Marketing Manager",
                    keyAccessor: (option) => option.userId,
                    valueAccessor: (option) => option.name,
                    onChange: (value) => {
                        setFieldsValue({marketingManagerId: value})
                    },
                },
            ]
        },
    ];


    useEffect(() => {
        listEvents.loadCourseList();
        listEvents.loadAgentList();
        setTimeout(() => {
            events.setDefaultFields();
        }, 200)
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.intake = intake;
                let resp = await dispatch(addStudentFxn(valData));
                if (resp && resp.success) {
                    form.setFieldsValue({
                        firstName: "",
                        lastName: "",
                        dateOfBirth: "",
                        dateOfBirthString: "",
                        email: "",
                        nationality: undefined,
                        studentType: undefined,
                        mobile: undefined,
                        address: "",
                        countryName: undefined,
                        stateName: undefined,
                        cityName: "",
                        postalCode: "",
                        courseId: undefined,
                        intakeTemp: undefined,
                        isEsl: undefined,
                        scholarship: undefined,
                        issueDate: "",
                        startDate: "",
                        endDate: "",
                        expirationDate: "",
                        agentId: undefined,
                        marketingManagerId: undefined
                    });
                    setIntake({})
                    events.loadAgentFxn()
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl">
                    <Card title={"Add Student"}>
                        <Form onSubmit={handleSubmit}>
                            {sections.map((section, sectionIndex) => (
                                <div className="form-section" key={sectionIndex}>
                                    <h6 className="form-title">{section.title}</h6>
                                    <div className="row">
                                        {section.fields.map((item, key) => (
                                            !item.hidden &&
                                            <div className={`${item.span ? item.span : "col-md-6"}`} key={key}>
                                                <GetEachFormFields
                                                    getFieldDecorator={getFieldDecorator}
                                                    getFieldError={getFieldError}
                                                    {...props.form}
                                                    item={item}
                                                    keyAccessor={item.keyAccessor}
                                                    valueAccessor={item.valueAccessor}
                                                    onChange={item.onChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <hr className="my-6 mx-n6"/>

                                </div>
                            ))}
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Form.create()(AddStudent);
